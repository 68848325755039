// default
@import './mixins/mixins';

a {
	text-decoration: none;
	color: inherit;
}

h1 {
	margin: 0;
}

body {
	font-family: current-font;
	font-size: 11.5px;
	overflow-y: hidden;
	overflow-wrap: break-word;
	font-feature-settings: 'kern';
	// smooth scrolling on IOS
	-webkit-overflow-scrolling: touch; // sass-lint:disable-line no-vendor-prefixes
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0); // sass-lint:disable-line no-vendor-prefixes
	@include M {
		font-size: 8.6px;
	}
	@include XXXL {
		font-size: 10px;
	}
	/** {
		&::-webkit-scrollbar-track {
			border: 1px solid rgba(0, 0, 0, 0.05);
			border-radius: 10px;
			background-color: transparent;
		}
		&::-webkit-scrollbar { width: 10px; }
		&::-webkit-scrollbar-thumb {
			border-radius: 10px;
			background: linear-gradient(to bottom, var(--color--interactive--background) 0%, darken(var(--color--interactive--background), 2%) 50%, darken(var(--color--interactive--background), 8%) 100%);
		}
	}*/
}

#ue_widget #ue_launcher {
	// sass-lint:disable-line no-ids
	bottom: 100px !important; // sass-lint:disable-line no-important
}

// two universal classes that must always be respected, exceptions to no-important rule
.hidden,
[hidden] {
	// sass-lint:disable-block no-important
	display: none !important;
}
.invisible {
	// sass-lint:disable-block no-important
	visibility: hidden !important;
}
